<template>
  <v-btn icon @click="switchLocale">
    <v-icon>mdi-translate</v-icon>
  </v-btn>
</template>

<script>
import { saveLocale } from '@/i18n';

export default {
  name: 'LocaleSwitchWidget',
  methods: {
    switchLocale() {
      const locale = this.$i18n.locale === 'en' ? 'ja' : 'en';
      this.$i18n.locale = locale;
      saveLocale(locale);
    }
  }
}
</script>
